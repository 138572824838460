<template>
  <v-row>
    <v-col cols="12" md="6">
      <lookup-dominio label="* Vence em"
                      :hidden-clear="false"
                      hint="Selecione o Status"
                      v-model="value.qtdDias"
                      :type="lookups.qtdDias"
                      :rules="[
                      this.$validators.string.required,
                      ]"
                      />
    </v-col>    
    <v-col cols="12" md="6">
      <search-box
          label="* Contratado"
          popup-label="Selecione um Contratado"
          :id.sync="value.idContratado"
          :descricao.sync="value.descricaoContratado"
          :hidden-clear="false"
          :value="contratado"
          :loader-fn="loaderContratado"
          item-key="id"
          item-text="descricao"
          >
        <template v-slot:empty>
          <p class="ma-2">
            Nenhum Contratado encontrado
          </p>
        </template>
      </search-box>
    </v-col>       
  </v-row>
</template>

<script>
import {ItemDeDominio} from "../../../../api/itemsDominio";
import LookupDominio from "../../common/LookupDominio";
import OrderInput from "../../common/OrderInput";
import SearchBox from "../../common/SearchBox";
import {findAll as findAllContratado} from "@/api/contratado";
import moment from 'moment';

export default {
  name: "RelatorioVigenciaContratoForm",
  components: {
    SearchBox,
    LookupDominio,
    OrderInput
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      lookups: {
        qtdDias: ItemDeDominio.ARR_QTD_DIAS_VIGENCIA,
      },      
      itens: [
        { id: 30, desc: "30 dias" },
        { id: 60, desc: "60 dias" },
        { id: 90, desc: "90 dias" }
      ]
    }
  },
  watch: {
    'value.idContratado': {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$refs.comboContrato.resetValues();
          this.$refs.comboContrato.search();
        }
      }
    },
  },
  computed: {
    inicioMes: function () {
      if (typeof this.value.dataIni === "undefined"){
        this.value.dataIni = moment().startOf('month').format('YYYY-MM-DD');
      }
    },
    fimMes: function () {
      if (typeof this.value.dataFim === "undefined"){
        this.value.dataFim = moment().endOf('month').format('YYYY-MM-DD');
      }
    },
    contrato(){
      if (this.value.idContrato == null) return null;
      return {
        id: this.value.idContrato,
        descricaoCompleta: this.value.descricaoCompleta
      }      
    }, 
    contratado() {
      if (this.value.idContratado == null) return null;
      return {
        id: this.value.idContratado,
        descricao: this.value.descricaoContratado
      }
    },          
  },
  methods: {
    loaderContratado(page, search) {
      return findAllContratado(page, {
        nome: search
      })
    },    
  }
}
</script>

<style scoped lang="scss">
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}


</style>